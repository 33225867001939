<template>
    <div class="yinhang-status">
        <div class="header acea-row row-between-wrapper" ref="header">
            <div class="black" @click="returnChange()">
                <img src="@assets/images/icon_black.png" alt="" />
            </div>
            <p>银行转账</p>
        </div>
        <div class="yinhang-order">
            <div class="order-item acea-row row-between">
                <p>订单号：</p>
                <p>{{order_id}}</p>
            </div>
            <div class="order-item acea-row row-between">
                <p>应付金额：</p>
                <p><span>¥{{total_price}}</span></p>
            </div>
        </div>
        <div class="yinhang-number">
            <div class="title">
                <p>指定收款账号</p>
            </div>
            <div class="bank-info">
                <p><b>户 名：</b><span>{{bankInfo.account_name}}</span></p>
                <p><b>开户银行：</b><span>{{bankInfo.bank_address}}</span></p>
                <p><b>账 号：</b><span>{{bankInfo.bank_account}}</span></p>
            </div>
        </div>
        <div class="bank-num acea-row row-between">
            <p>输入实际汇款金额：</p>
            <div class="acea-row row-between">
                <p>￥</p>
                <input
                type="number"
                placeholder="请输入"
                v-model="shijimoney"
                required
            />
            </div>
        </div>
        <div class="shuoming">
            <div class="name acea-row row-center">
                <!-- <span>上传汇款凭证</span> -->
                <div class="upde">
                    <a href="">
                        <VueCoreImageUpload
                            class="btn btn-primary"
                            :crop="false"
                            compress="80"
                            @imageuploaded="imageuploaded"
                            :headers="headers"
                            :max-file-size="5242880"
                            :credentials="false"
                            inputAccept="image/*"
                            inputOfFile="file"
                            :url="url"
                            ref="upImg">上传汇款凭证</VueCoreImageUpload>
                    </a>
                </div>
            </div>
            <div class="arent">
                <p>转账须知：</p>
                <p>1.请在汇款时在"用途栏"中注明您的订单号以及汇款金额，
                    以便我们及时确认订单、发货。</p>
                    
                <p>    2.请您在汇款后立刻与我们客服联系，我们将在2-5个工作
                    日内审核，审核完成后订单会转为已支付状态，期间请勿
                    重复转账！</p>
                    
                    <p>     3.汇款凭证应清晰显示有汇出银行的户名、卡号；以及收款
                    银行的户名、卡号及汇出金额。</p>
            </div>
        </div>
        <div class="detail-btn acea-row row-between">
            <div class="right pay-done" @click="toPayAliPay">
              <p>去支付</p>
            </div>
        </div>
    </div>
</template>
<script>
import { VUE_APP_API_URL } from "@utils";
import { getSiteServicePhone, saveBankPay } from "@api/order";
import VueCoreImageUpload from "vue-core-image-upload";
export default {
    name:'YinHangPay',
    data() {
        return {
            url: `${VUE_APP_API_URL}/upload/image`,
            headers: {
	            "Authori-zation": "Bearer " + this.$store.state.app.token
	        },
            order_id:this.$route.query.order_id? this.$route.query.order_id:0,
            total_price:this.$route.query.total_price? this.$route.query.total_price:0,
            bankInfo:'',
            shijimoney:'',
            purchase_image:'',
        }
    },
    components: {
	    VueCoreImageUpload,
	},
    mounted() {
        this.getSiteServicePhoneChange();
    },
    methods: {
         //返回上一页
        returnChange() {
            var that = this;
            that.$router.go(-1);
        },
        //上传汇款凭证
	    imageuploaded(res) {
	      if (res.status !== 200) {
	      	this.$dialog.error(res.msg || "上传图片失败");
	      	return false;
	      } else {
            this.$dialog.success(res.msg).then(() => {
                this.purchase_image = res.data.url;   
		    });
          }
	    },
        //获取银行信息
        getSiteServicePhoneChange() {
            let data = {};
            getSiteServicePhone(data).then((res)=>{
                // console.log(res);
                this.bankInfo = res.data;
            }).catch((res)=>{
                console.log(res)
            })
        },
        //提交
        toPayAliPay() {
            if(!this.shijimoney) {
                this.$dialog.error("请输入实际汇款金额");
                return false;
            }else if (!this.purchase_image) {
                this.$dialog.error("请上传汇款凭证");
                return false;
            }
            let data ={
                img_url:this.purchase_image,
                order_sn:this.order_id,
            }
            saveBankPay(data).then((res)=>{
                if(res.status == 200) {
                    this.$dialog.success(res.msg).then(() => {
                        this.$router.push({
                            path:'/banksuccess'
                        })
                    });
                } else {
                    this.$dialog.error(res.msg);
                }
            }).catch((error)=>{
                this.$dialog.error(error.msg);
            })
        }
    }
}
</script>

<style type="text/css">
.yinhang-status { height:100%; }

.yinhang-status .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
  position:relative;
}
.yinhang-status .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
  margin-top:15px;
}
.yinhang-status .header .black img {
  width: 100%;
  height: 100%;
}
.yinhang-status .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
  line-height: 0.96rem;
}
.yinhang-status .header p.admin {
  width:auto;
  position: absolute;
  right: 8px;
  top: 0px;
  font-size: 14px;
  color: #666;
}
</style>